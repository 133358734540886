import React, { useState } from 'react';
import './css/ContactUs.css';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}



const FORM_DEFAULT = { name: '', email: '', phone: '', message: '' }


export default function ContactUs() {
    const [state, setState] = useState({ ...FORM_DEFAULT });
    const { name, email, phone, message } = state;

    const handleChange = e => {
        const { name: targetName, value: targetValue } = e.target;
        setState(({ ...state, [targetName]: targetValue }));
    };

    const handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...state })
        })
            .then(() => {
                setState({ ...FORM_DEFAULT });
                alert("Email sent!");
            })
            .catch(error => alert(error));

        e.preventDefault();
    };
    
    return (
        <div className='contact-us-form' id='contact'>
            <div className='contact-header'>Contact Us</div>

            <div className='sub-header'>Reach out to us for your business inquiries and concerns.</div>

            <div className='contact-body'>
                <div className='riverline-info'>
                    <div className='phone-container'>
                        <div className='phonenumber'>Phone Number</div>
                        <a className='phonenumber-data' href='tel:+16094030272'>(+1) 609-403-0272</a>
                    </div>

                    <div className='email-container'>
                        <div className='email'>Email</div>
                        <a className='email-data' href='mailto:contact@riverlinecd.com'>contact@riverlinecd.com</a>
                    </div>
                </div>

                <form onSubmit={handleSubmit} method="POST" data-netlify="true">
                    <div className='contact-form'>
                        <input name="name" placeholder='Name' value={name} onChange={e => handleChange(e)} />
                        <input name="email" placeholder='Email' value={email} onChange={handleChange} />
                        <input name="phone" type='number' placeholder='Phone number' value={phone} onChange={handleChange} />
                        <div className='message-area'>
                            <textarea placeholder='Message' rows='8' name="message" value={message} onChange={handleChange} />
                            <button type="submit">Send Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div >
    );
}