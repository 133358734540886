import React from 'react';
import './css/Header.css';

//<img src={SplashArt} alt='Womans hand' width='600' height='400' />
//rubik - headers / karla - body
//orange - FF8C00 / green - 53CF3C / footer-divider-grey - 403F3F / header-grey - 3F3E3E
export default function Header() {
    return (
        <div className='container-panel-1'>
            <div className='container-group'>
                <div className='sub-panel-1'>
                    <div className='sub-panel-1-we-background'>
                        <div className='sub-panel-1-we-highlight'>
                            <div className='sub-panel-1-we'>
                                We
                        </div>
                        </div>
                    </div>

                    <div>
                        <div className='sub-panel-1-encourage-background'>
                            <div className='sub-panel-1-encourage-highlight'>
                                <div className='sub-panel-1-encourage'>
                                    encourage
                            </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='sub-panel-1-change-background'>
                            <div className='sub-panel-1-change-highlight'>
                                <div className='sub-panel-1-change'>
                                    change.
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className='sub-panel-1-background'>
                        <p className='sub-panel-1-about'>
                            We at Riverline Creative and Riverline Development believe change can be one of the most difficult yet necessary things we face.
                            With the right guidance and collaboration, we embrace the challenges and ideas brought to us and strive
                            to help our clients seize new opportunities to better their businesses.
                    </p>
                    </div>
                </div>
            </div>
        </div>
    );
}