import React, {useState} from 'react';
import GreenLogo from './logos/creative.green.gray.r.png';
import OrangeLogo from './logos/development.orange.gray.r.png';
import './css/NavBar.css';


export default function NavBar() {
    const [open, setOpen] = useState(false);
    const css = open ? 'navbar-links active'  : 'navbar-links';

    function handleClick(e){
        setOpen(!open)
    }

    return (
        <div className='navbar'>
            <div className='brand-title'>
                <img src={GreenLogo} alt='Riverline creative' height='50' width='50' />
                <div></div>
                <img src={OrangeLogo} alt='Riverline development' height='50' width='50' />
            </div>

            <a href='#' className='toggle-button' onClick={e => { e.preventDefault(); handleClick()}}>
                <div className='bar'></div>
                <div className='bar'></div>
                <div className='bar'></div>
            </a>

            <div className={css}>
                <ul>
                    <li><a href='#' onClick={handleClick}>Home</a></li>
                    <li><a href='#about' onClick={handleClick}>About</a></li>
                    <li><a href='#contact' onClick={handleClick}>Contact</a></li>
                </ul>
            </div>
        </div>
    );
}