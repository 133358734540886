import React from 'react';

export default function WhoWeAre() {
    return (
        <div className='txt-panel-container'>
            <div className='txt-panel-header'>
                Who We Are
            </div>

            <div className='txt-panel-body'>
            We are a team of designers, developers, and copywriters going above and beyond to bring our clients the change their business needs. 
            Our experiences and backgrounds give us the ability to diversify and rejuvenate your brand's ecosystem.
            </div>
        </div>
    );
}