import React from 'react';

export default function AboutUs() {
    return (
        <div className='txt-panel-container' id='about'>
            <div className='txt-panel-header'>
                About Us
            </div>

            <div className='txt-panel-body'>
                Riverline Creative and Riverline Development are family owned and operated in Central New Jersey.
                Despite being two separate businesses, we work together as one team to create and provide our clients with the best quality content and a seamless and effortless experience throughout the whole process.
            </div>
        </div>
    );
}