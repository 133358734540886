import React from 'react';
import './App.css';
import './components/css/TextPanel.css';
import './components/css/PanelFooter.css';
import NavBar from './components/Navigation';
import Header from './components/Header';
import OurFocus from './components/OurFocus';
import CircleDivider from './components/CircleDivider';
import WhoWeAre from './components/WhoWeAre';
import StaffView from './components/StaffView';
import AboutUs from './components/AboutUs';
import PanelFooter from './components/PanelFooter';
import ContactUs from './components/ContactUs';

function App() {
  return (
    <div className="App">
      <div className='Header'>
        <NavBar />
      </div>

      <div className='panel-body'>
        <div className='panel-1'>
          <Header />
        </div>

        <div className='panel-2'>
          <OurFocus />
        </div>

        <div className='panel-3'>
          <CircleDivider />
        </div>

        <div className='panel-4'>
          <WhoWeAre />
        </div>

        <div className='panel-5'>
          <StaffView />
        </div>

        <div className='panel-6'>
          <AboutUs />
        </div>

        <div className='contact-panel'>
          <ContactUs />
        </div>

        <div className='panel-footer'>
          <PanelFooter />
        </div>
      </div>
    </div>
  );
}

export default App;
