import React from 'react';
import './css/Staff.css';
import Daniel from './art/Dan.jpg';
import Nick from './art/Nick.jpg';
import Cara from './art/Cara.jpg';
import Katelyn from './art/Katelyn.jpg';

const COMPANY_STAFF = [
    { image: Daniel, name: "Daniel Migliaccio", position: "Designer", type: 'creative' },
    { image: Katelyn, name: "Katelyn Brandmaier", position: "Digital Copywriter", type: 'creative' },
    { image: Nick, name: "Nicholas Migliaccio", position: "Developer", type: 'development' },
    { image: Cara, name: "Cara Lane", position: "Administrative Specialist", type: 'development' },
]


export default function StaffView() {
    return (
        <div className="panel-square-list">
            {COMPANY_STAFF.map((x, i) => <div key={i} className="square-container">
                <div className={`square-base square-type-${x.type}`}>
                    <img src={x.image} alt={x.name} className="square-image" />
                    <div className="square-footer">
                        <div className="square-name">{x.name}</div>
                        <div className="square-position">{x.position}</div>
                    </div>
                </div>
            </div>)}
        </div>
    )
}

// export default function StaffView() {
//     return (
//         <div className='panel-5-container'>
//             <div className='square-container'>

//                 <div className='square-base square-creative'>
//                     <img src={Daniel} alt='Daniel Migliaccio' className='staff-image' />

//                     <div className='square-creative-highlight'>
//                         <div className='square-creative-text'>
//                             Daniel Migliaccio
//                         </div>

//                         <div className='square-creative-position'>
//                             Designer
//                         </div>
//                     </div>
//                 </div>

//                 <div className='square-base square-development'>
//                     <img src={Daniel} alt='Daniel Migliaccio' className='staff-image' />

//                     <div className='square-development-highlight'>
//                         <div className='square-development-text'>
//                             Nicholas Migliaccio
//                         </div>

//                         <div className='square-development-position'>
//                             Developer
//                         </div>
//                     </div>
//                 </div>


//                 <div className='square-base square-creative'>
//                     <img src={Daniel} alt='Daniel Migliaccio' className='staff-image' />

//                     <div className='square-creative-highlight'>
//                         <div className='square-creative-text'>
//                             Katelyn Brandmaier
//                         </div>

//                         <div className='square-creative-position'>
//                             Digital Copywriter
//                         </div>
//                     </div>
//                 </div>

//                 <div className='square-base square-development'>
//                     <img src={Daniel} alt='Daniel Migliaccio' className='staff-image' />

//                     <div className='square-development-highlight'>
//                         <div className='square-development-text'>
//                             Cara Lane
//                         </div>

//                         <div className='square-development-position'>
//                             Administrator
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }