import React from 'react';

export default function OurFocusPanel() {
    return (
        <div className='txt-panel-container'>
            <div className='txt-panel-header'>
                Our Focus
            </div>

            <div className='txt-panel-body'>
                We believe access to great design should be an arms reach for all businesses. In today’s world, having a top notch brand identity is a requirement.
            </div>
        </div>
    );
}