import React from 'react';
import './css/Panel3.css';
import Branding from './art/branding.png';
import Dev from './art/dev.png';
import Digital from './art/digital.png';
import Print from './art/print.png';

export default function CircleDivider() {
    return (
        <div className='panel-3-container'>
            <div className='circle-container'>
                <div className='circle'>
                    <img src={Branding} alt='Branding' className='circle-logo' />

                    <div className='circle-text'>
                        Branding
                    </div>
                </div>

                <div className='circle'>
                    <img src={Dev} alt='Website development' className='circle-logo' />

                    <div className='circle-text'>
                        Website Development
                    </div>
                </div>

                <div className='circle'>
                    <img src={Print} alt='Print design' className='circle-logo' />

                    <div className='circle-text'>
                        Print Design
                    </div>
                </div>

                <div className='circle'>
                    <img src={Digital} alt='Digital design' className='circle-logo' />

                    <div className='circle-text'>
                        <p>Digital Design</p>
                    </div>
                </div>
            </div>
        </div>
    );
}