import React from 'react';

export default function PanelFooter(){
    //© 2020 riverline Creative and Riverline Development
    return(
        <div className='panelfooter-container'>
            <div className='bar-1'></div>
            <div className='bar-2'></div>
            <div className='bar-3'>© 2020 Riverline Creative and Riverline Development</div>
            <div className='bar-4'></div>
        </div>
    );
}